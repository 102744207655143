import React from "react"
import { Link } from "gatsby"

import "./icon-block.scss"

const IconBlock = ({ icon, title, target, children }) => {
  return (
  <div className="icon-block">
    {icon}
    <h3>
      <Link to={target}>{title}</Link>
    </h3>
    {children}
    <Link to={target}>Learn More</Link>
  </div>
  )
}

export default IconBlock
