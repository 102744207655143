import React from "react"
import { graphql } from "gatsby"

import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"

import LayoutMain from "../components/layout-main/layout-main"
import SeoHeaders from "../components/seo-headers/seo-headers"
import HeroUnit from "../components/hero-unit/hero-unit"
import RecentArticles from "../components/recent-articles/recent-articles"
import ServiceTestimonials from "../components/service-testimonials/service-testimonials"
import HireUsBanner from "../components/hire-us-banner/hire-us-banner"
import ServiceList from "../components/service-list/service-list"
import ImageBlock from "../components/image-block/image-block"
import VideoEmbed from "../components/video-embed/video-embed"

import { normalizeServices, recentPosts } from "../utils/utils"

export default function Template({ data }) {
  const { service, related, testimonials, services } = data
  const { frontmatter, body } = service
  const posts = recentPosts(related.nodes.map((o) => ({ node: { ...o } })))

  const {
    category,
    categorySlug,
    slug,
    title,
    heroImage,
    heroText,
    imageList,
  } = frontmatter

  const normalizedServices = normalizeServices(services?.nodes, slug)

  const shortcodes = { ImageBlock, VideoEmbed }

  return (
    <LayoutMain className={`page-service page-${slug}`}>
      <SeoHeaders isBlogPost={false} frontmatter={{}} title={title} />
      <HeroUnit heroImage={heroImage} pageTitle={title}>
        <p>{heroText}</p>
      </HeroUnit>
      <section>
        <div className="section-service-content">
          <MDXProvider components={shortcodes}>
            <div className="service-content-inner">
              <MDXRenderer>{body}</MDXRenderer>
            </div>
            <ImageBlock
              image1={imageList[0]}
              image2={imageList[1]}
              image3={imageList[2]}
            />
          </MDXProvider>
        </div>
      </section>
      {services.nodes && services.nodes.length ? (
        <section>
          <ServiceList services={normalizedServices} />
        </section>
      ) : (
        ""
      )}
      <section>
        <ServiceTestimonials testimonials={testimonials} />
      </section>
      {posts && posts.length ? (
        <section>
          <RecentArticles
            category={category}
            categorySlug={categorySlug}
            recentPosts={posts}
          />
        </section>
      ) : (
        ""
      )}
      <section>
        <HireUsBanner />
      </section>
    </LayoutMain>
  )
}

export const ServiceCategoryBySlug = graphql`
  query ($slug: String!, $categorySlug: String!) {
    service: mdx(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        title
        metaTitle
        slug
        categorySlug
        category
        description
        heroText
        imageList {
          id
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
            id
          }
        }
        heroImage {
          id
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      id
      body
    }
    testimonials: allFile(
      filter: {
        sourceInstanceName: { eq: "testimonials" }
        extension: { eq: "mdx" }
        childMdx: { frontmatter: { status: { eq: "published" } } }
      }
    ) {
      nodes {
        id
        childMdx {
          body
          frontmatter {
            slug
            businessName
            customerName
            customerTitle
            avatar {
              id
              childImageSharp {
                fluid(maxWidth: 250) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
    services: allFile(
      filter: {
        sourceInstanceName: { eq: "services" }
        extension: { eq: "mdx" }
        childMdx: { frontmatter: { status: { eq: "published" } } }
      }
    ) {
      nodes {
        id
        childMdx {
          frontmatter {
            slug
            serviceCategory
            title
            excerpt
          }
        }
      }
    }
    related: allFile(
      filter: {
        extension: { eq: "mdx" }
        childMdx: {
          frontmatter: {
            categorySlug: { eq: $categorySlug }
            status: { eq: "published" }
          }
        }
        sourceInstanceName: { eq: "articles" }
      }
    ) {
      nodes {
        id
        childMdx {
          excerpt
          frontmatter {
            title
            slug
            date
            banner {
              id
              childImageSharp {
                fluid(maxWidth: 500) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`
