import React from "react"
import SpeechBubble from "../speech-bubble/speech-bubble"

const ServiceTestimonials = ({ testimonials = { nodes: [] } }) => {
  return (
    <div className="section-service-testimonials">
      <div className="service-content-inner">
        <h2 className="elr-text-center elr-bold text-shadow">
          What Our Clients Say
        </h2>
        <p className="elr-text-center">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit debitis
          amet eos natus corporis totam ea, non perspiciatis nulla eveniet.
        </p>
        <p className="elr-text-center">
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Itaque, enim
          repellat modi nam tempore rerum consequuntur totam illo, fugiat
          laborum nostrum voluptatem quas, sed molestias.
        </p>
      </div>
      <ul className="service-testimonial-list">
        {
          testimonials.nodes && testimonials.nodes.length && testimonials.nodes.map(testimonial => {
            return (
              <li key={testimonial.id}><SpeechBubble testimonial={testimonial} /></li>
            )
          })
        }
      </ul>
    </div>
  )
}

export default ServiceTestimonials
