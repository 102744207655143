import React from "react"

import Img from "gatsby-image"
import { MDXRenderer } from "gatsby-plugin-mdx"
// import NoteTakingImage from "../../images/note-taking.jpg"
import "./speech-bubble.scss"

const SpeechBubble = ({ testimonial }) => {
  // console.log("speech bubble", testimonial)
  const {
    childMdx: { frontmatter: { avatar, customerTitle, customerName, businessName }, body },
  } = testimonial
  const featuredImage =
    avatar && avatar.childImageSharp
      ? avatar.childImageSharp.fluid
      : null

  return (
    <div className="speech-bubble">
      <div className="avatar">
        <Img fluid={featuredImage} alt={customerName} />
      </div>
      <blockquote>
        <MDXRenderer>{body}</MDXRenderer>
        <cite>
          <span>{customerName}</span>
          <span>
            {businessName} - {customerTitle}
          </span>
        </cite>
      </blockquote>
    </div>
  )
}

export default SpeechBubble
