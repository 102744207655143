import React from "react"

import IconBlock from "../icon-block/icon-block"
import SocialIcon from "../../images/icon-social.svg"

const ServiceList = ({ services = [] }) => {
  return (
    <div className="section-service-list">
      <ul className="service-list">
        {
          services.map(({ slug, title, excerpt, id }) => {
            return (
              <li key={id} className="service-list-item">
                <IconBlock
                  icon={<SocialIcon />}
                  target={`/service/${slug}`}
                  title={title}
                >
                  <p>{excerpt}</p>
                </IconBlock>
              </li>
            )
          })
        }
      </ul>
    </div>
  )
}

export default ServiceList
